<template>
	<div v-if="isdiv" class="driveinfo">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="预览试乘试驾邀请函"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11" 
		:border="false"
		placeholder
		>
	</van-nav-bar>
		
		<img class="img_top" :src="serverUrl(invitationinfo.head_image)" mode="">
	
		<div class="driveinfo_top">
			{{invitationinfo.content[0]}}
			<div><span style="visibility: hidden;">空格</span> {{invitationinfo.content[1]}}</div>
		</div>
		
		<div class="driveinfo_mine" v-if="invitationinfo.store_service?.length > 0">
			<div class="driveinfo_mine_title">
				我的专属服务
			</div>
			<div class="driveinfo_mine_concat">
				专属服务项目可让产品专家在APP的“试乘试驾 > 我的 > 邀请”中设置。
			</div>
			<div @click="ischoos(v.id)" v-for="v,index in invitationinfo.store_service" :key="index" class="driveinfo_mine_list">
				<img v-if="form.service_ids.indexOf(v.id) != -1" src="@/assets/drivroute/icon_xuandui.png" mode="">
				<img v-else src="@/assets/drivroute/icon_weixuanz.png" mode="">
				<span>{{v.name}}</span>
			</div>
		</div>
		
		<div class="driveinfo_shop">
			<img :src="serverUrl(invitationinfo.program.image)" mode="">
			<span>{{invitationinfo.program.name}}</span>
		</div>
		
		<div v-if="islat" class="driveinfo_map">
			<div class="driveinfo_map_left">
				<Map :center="lat"></Map>
				<!-- <iframe :src="`https://apis.map.qq.com/tools/poimarker?type=0&marker=coord:39.96554,116.26719;title:成都;addr:北京市海淀区复兴路32号院|coord:39.87803,116.19025;title:成都园;addr:北京市丰台区射击场路15号北京园博园|coord:39.88129,116.27062;title:老成都;addr:北京市丰台区岳各庄梅市口路西府景园六号楼底商|coord:39.9982,116.19015;title:北京园博园成都园;addr:北京市丰台区园博园内&key=CYWBZ-R5UKV-LFGPO-UDLQ2-E5DJ7-MHF7J&referer=myapp`"></iframe> -->
				<!-- <map scale="16" style="width: 242px; height: 100%;" :latitude="lat[1]" :longitude="lat[0]" :markers="covers"></map> -->
			</div>
			<div class="driveinfo_map_rigth">
				<div class="driveinfo_map_title">
					{{invitationinfo.store_name}}
				</div>
				<div class="driveinfo_map_add">
					<img src="@/assets/drivroute/icon_dinwei.png" mode="">
					<span>{{invitationinfo.address}}</span>
				</div>
				<div class="driveinfo_map_photo">
					<img src="@/assets/drivroute/icon_model.png" mode="">
					<span>{{invitationinfo.contact}}</span>
				</div>
			</div>
		</div>
		
		<div class="driveinfo_footer">
			<van-image class="center_avatar" round :src="serverUrl(invitationinfo.avatar)" fit="cover"/>
			<span class="driveinfo_footer_name">{{invitationinfo.bio}}</span>
			<span class="driveinfo_footer_title">您的专属销售顾问{{invitationinfo.username}}随时为您服务</span>
		</div>
		
		<div style="height: 91px;" :style="{'top':(docmHeight - 91) + 'px'}"  class="user_footer">
			<div class="" @click="save">
				发送邀请
			</div>
			<!-- <div open-type="share" class="">
				发送邀请
			</div> -->
		</div>
	</div>
</template>

<script>
import {getsignature} from '@/utils/utils'
import Map from '../../components/map.vue'
	export default {
		components:{
			Map
		},
		data(){
			return{
				scrollTop: 0,
				form:{},
				invitationinfo:{},
				lat:[],
				invitation_id:'',
				isshare:false,
				islat:true,
				isdiv:false,
			}
		},
		mounted(){
			this.form = JSON.parse(this.$route.query.option)
			this.getlicense()
			this.$set(this.form,'service_ids',[])
		},
		computed: {    
			docmHeight() {
				return this.$store.state.height;
			}
		},
		methods:{
			// 发送邀请
			async save(){
				this.form.store_id = 0
				this.form.service_ids = this.form.service_ids?.join()
				this.form.model_id = this.form.model_id + ''
				this.form.license_id = parseInt(this.form.license_id)
				const {data:res} = await this.$http.post('/api/scsj/create_scsj_invitation_1_7_2',this.form)
				if(res.code == 1){
					this.invitation_id = res.data.res.invitation_id
					this.$toast.success('创建成功')
					this.isshare = true
					const url = localStorage.getItem('share')
					this.$wx.invoke(
						"shareAppMessage", {
							title: '试乘试驾邀请函', // 分享标题
							desc: '', // 分享描述
							link: url+'/scsj/scsjsjyq/?type=1&id=' + this.invitation_id, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
							imgUrl: 'https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/logo.jpeg', // 分享图标
							enableIdTrans: 0, // 是否开启id转译，不填默认为0
						}, function(res) {
								if (res.err_msg == "shareAppMessage:ok") {
									this.$toast.success('分享成功')						//正确处理
								}else {
									getsignature()					//错误处理
								}
						}
					);
						// this.$wx.onMenuShareAppMessage({
						// 	title: '试乘试驾邀请函', // 分享标题
						// 	desc: '', // 分享描述
						// 	link: url+'/share/test-drive-preview-share?id=' + this.invitation_id, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
						// 	imgUrl: 'https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/logo.jpeg', // 分享图标
						// 	enableIdTrans: 1, // 是否开启id转译，不填默认为0
						// 	success: function (res) {
						// 		this.$toast.success('分享成功')
						// 			// 用户确认分享后执行的回调函数
						// 	},
						// 	error: function(res) {
						// 		getsignature()
						// 	},
						// 	cancel: function () {
						// 		this.$toast.success('取消分享')
						// 			// 用户取消分享后执行的回调函数
						// 	}
				// });
					
				}else{
					this.$toast.fail(res.msg)
				}
			},
			// 是否选中
			ischoos(id){
				const isid = this.form.service_ids.indexOf(id)
				if(isid == -1){
					this.form.service_ids.push(id)
				}else{
					this.form.service_ids.splice(isid,1)
				}
			},
			// 获取详情
			async getlicense(){
				const {data:res} = await this.$http.post('/api/scsj/preview_scsj_invitation',this.form)
				if(res.code == 1){
					this.invitationinfo = res.data.res
					this.form.service_ids = res.data.res.store_service?.map(item => item.id)
					if(this.invitationinfo.lng_lat != null){
						const indexs = this.invitationinfo.lng_lat.indexOf(',')
						this.lat[0] = parseFloat(this.invitationinfo.lng_lat.slice(0,indexs)) 
						this.lat[1] = parseFloat(this.invitationinfo.lng_lat.slice(indexs+1))
					}else{
						this.islat = false
					}
					this.isdiv = true
					
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
.driveinfo{
	padding-bottom: 200px;
	.img_top{
		width: 750px;
	}
	.driveinfo_top{
		padding: 30px 32px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		display: flex;
		flex-direction: column;
		color: #FFFFFF;
		line-height: 48px;
	}
	.driveinfo_shop{
		border: 1px solid #979797;
		padding: 28px;
		margin: 60px 32px 0;
		display: flex;
		align-items: center;
		img{
			height: 144px;
			width: 144px;
		}
		span{
			margin-left: 40px;
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #FFFFFF;
		}
	}
	.driveinfo_map{
		padding: 40px 32px;
		display: flex;
		.driveinfo_map_left{
			height: 242px;
			width: 242px;
			background-color: #fff;
		}
		.driveinfo_map_rigth{
			margin-left: 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.driveinfo_map_title{
				width: 350px;
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #ACACAC;
				line-height: 40px;
			}
			.driveinfo_map_photo{
				display: flex;
				img{
					width: 32px;
					height: 28px;
				}
				span{
					width: 100%;
					margin-left: 20px;
					font-size: 32px;
					font-family: HYQiHeiY3-45;
					font-weight: normal;
					color: #ACACAC;
					line-height: 40px;
				}
			}
			.driveinfo_map_add{
				margin-top: 30px;
				display: flex;
				img{
					width: 32px;
					height: 28px;
				}
				span{
					width: 350px;
					overflow:hidden;
					text-overflow:ellipsis;
					display: -webkit-box; 
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2; 
					margin-left: 20px;
					font-size: 32px;
					font-family: HYQiHeiY3-45;
					font-weight: normal;
					color: #ACACAC;
					line-height: 34px;
				}
			}
		}
		
	}
	.driveinfo_footer{
		padding: 40px;
		display: flex;
		align-items: center;
		flex-direction:column;
		.center_avatar{
			width: 120px;
			height: 120px;
			border: 1px solid #FA0037;
			border-radius: 50%;
		}
		.driveinfo_footer_name{
			margin-top: 32px;
			font-size: 40px;
			font-family: HYQiHeiY3-45;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 40px;
		}
		.driveinfo_footer_title{
			margin-top: 32px;
			font-size: 32px;
			color: #BFBFBF;
			line-height: 32px;
		}
	}
	.driveinfo_mine{
		margin: 20px 32px;
		.driveinfo_mine_title{
			font-size: 40px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #FFFFFF;
			padding: 20px 0;
			text-align: center;
		}
		.driveinfo_mine_concat{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #ACACAC;
		}
		.driveinfo_mine_list{
			margin-top: 30px;
			padding: 28px;
			display: flex;
			background: #222222;
			border-radius: 8px;
			img{
				height: 32px;
				width: 32px;
			}
			span{
				margin-left: 20px;
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 36px;
			}
		}
	}
}


.user_footer{
	z-index: 2;
	position: fixed;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #000000;
	text-align: center;
	div{
		width: 670px;
		line-height: 92px;
		height: 92px;
		background: #FA0037;
		text-align: center;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
	}
}
</style>